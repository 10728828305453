import { Component, OnInit } from '@angular/core';
import {Http} from '@angular/http';
import {TokenService} from '../common/token.service';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor(private router: Router, private http: Http, private Token: TokenService,private title: Title) {
  }

  ngOnInit() {
    this.title.setTitle('Octanics');
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.http.get(Configuration.serviceIdentityURL + '/checktoken?email=' + email + '&token=' + token + '&url=' + Configuration.serviceCoreURL).subscribe((res) => {
      if (res['_body'] !== '' && res['_body'] !== null && res['_body'] !== undefined) {
        res = res.json();
        if (res['success'] == 'true') {
          this.Token.checkTokenAtAuthenticate();
          if(localStorage.getItem('landingroute')!=='null'){
            this.router.navigateByUrl(localStorage.getItem('landingroute'));
          }else{
            this.router.navigate(['/login']);
          }
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      }else{
        this.router.navigate(['/login']);
        return false;
      }
    });
 }
}




